import $ from 'jquery';
import jQuery from 'jquery';
import * as AOS from 'aos/dist/aos.js';
import LazyLoad from "vanilla-lazyload";
import {
    pathToWebp
} from './pathToWebp.js';

global.lang = document.documentElement.lang
global.configJSON = () => {
    return $.getJSON('../assets/json/config.json');
};
global.filterShow = false //product page filter

window.addEventListener("DOMContentLoaded", () => {
    function getBroswer() {
        var Sys = {};
        var ua = navigator.userAgent.toLowerCase();
        var s;
        (s = ua.match(/edge\/([\d.]+)/)) ? Sys.edge = s[1]:
            (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1] :
            (s = ua.match(/msie ([\d.]+)/)) ? Sys.ie = s[1] :
            (s = ua.match(/firefox\/([\d.]+)/)) ? Sys.firefox = s[1] :
            (s = ua.match(/chrome\/([\d.]+)/)) ? Sys.chrome = s[1] :
            (s = ua.match(/opera.([\d.]+)/)) ? Sys.opera = s[1] :
            (s = ua.match(/version\/([\d.]+).*safari/)) ? Sys.safari = s[1] : 0;

        if (Sys.edge) return {
            broswer: "Edge",
            version: Sys.edge
        };
        if (Sys.ie) return {
            broswer: "IE",
            version: Sys.ie
        };
        if (Sys.firefox) return {
            broswer: "Firefox",
            version: Sys.firefox
        };
        if (Sys.chrome) return {
            broswer: "Chrome",
            version: Sys.chrome
        };
        if (Sys.opera) return {
            broswer: "Opera",
            version: Sys.opera
        };
        if (Sys.safari) return {
            broswer: "Safari",
            version: Sys.safari
        };

        return {
            broswer: "",
            version: "0"
        };
    }

    global.broswer = getBroswer()

    if (broswer.broswer != "IE") {
        document.documentElement.classList.add("webp")
    } else {
        document.documentElement.classList.add("no-webp")
    }

    if (broswer.broswer != "IE" && document.querySelectorAll("[data-bg]")) {
        pathToWebp("data-bg")
    }
})



AOS.init({
    //duration: 500,
    //once: true,
    offset: 0
});

$(function () {


    let lazyLoadInstance = new LazyLoad();




    $('.lang-btn').click(function () {

        if ($('.rwdFilter-coverBg')) {
            $('.rwdFilter-dropdown.show').removeClass('show')
            $('.rwdFilter-coverBg').removeClass('show')
            filterShow = false
        }

        if ($('nav.mainNav.active').length > 0) {
            $('.js-cls--btn').trigger('click')
        }
        $('.lang-box').toggleClass('active')
    });

    document.querySelector("body").onclick = function (e) {

        if (document.querySelector(".lang-btn").contains(e.srcElement)) return
        // 沒點到下拉篩選時，關閉下拉篩選
        let dropdownShow = document.querySelector(".lang-box")
        if (dropdownShow.classList.value.indexOf("active") > -1 && dropdownShow.contains(e.srcElement) == false) {
            dropdownShow.classList.remove("active")
        }

    }


    $('header select').on('change', function () {

        var path = window.location.href.replace(lang, this.value)
        window.location = path


    });


    $('.js-cls--btn').click(function () {
        if ($('.lang-box.active').length > 0) {
            $('.lang-btn').trigger('click')
        }
        //側選單關閉
        $('.navTrigger,header nav.mainNav,.js-cls--btn,main,footer').toggleClass('active');
    });
    $(window).bind('load scroll resize', function () {
        if ($(window).scrollTop() >= $(window).height() / 4) {
            $('.to_top').addClass('show')
        } else {
            $('.to_top').removeClass('show')
        }
    });

    $(".share").on('click', function (e) {
        $(".fab").removeClass("no");
        if (e.target != this) return;
        $('.share, .fab').toggleClass("active");
    });

    (function ($) {
        'use strict';

        $.socialfloating = function (options) {

            var defaults = {
                theme: 'light',
                opaque: false,
                effect: null,
                icons: 'fontawesome5',
                showHideButton: true,
                container: 'socialfloating',
                position: 'left',
                buttons: {}
            };

            var settings = $.extend({}, defaults, options);

            var container = $('#' + settings.container);

            if (container.length === 0) {

                $('body').append('<div id="' + settings.container + '" class="contact-bar ' + settings.position + '">');

                container = $('#' + settings.container);
                addButtons(container, settings.buttons, false, settings.icons);
                container.addClass(settings.effect);

                if (settings.showHideButton === true) {
                    var sShowHideButton;
                    switch (settings.icons) {
                        case 'fontawesome4':
                            switch (settings.position) {
                                case 'right':
                                    sShowHideButton = '<a class="cb-link toggle-bar"><span class="fa-solid fa-chevron-right" aria-hidden="true"></span></a>';
                                    break;
                                default:
                                    sShowHideButton = '<a class="cb-link toggle-bar"><span class="fa-solid fa-chevron-left" aria-hidden="true"></span></a>';
                                    break;
                            }
                            break;
                        case 'semanticui':
                            switch (settings.position) {
                                case 'right':
                                    sShowHideButton = '<a class="cb-link toggle-bar"><i class="fa-solid fa-chevron-right"></i></a>';
                                    break;
                                default:
                                    sShowHideButton = '<a class="cb-link toggle-bar"><i class="fa-solid fa-chevron-left"></i></a>';
                                    break;
                            }
                            break;
                        default:
                            switch (settings.position) {
                                case 'right':
                                    sShowHideButton = '<a class="cb-link toggle-bar"><span class="fa-solid fa-chevron-right" aria-hidden="true"></span></a>';
                                    break;
                                default:
                                    sShowHideButton = '<a class="cb-link toggle-bar"><span class="fa-solid fa-chevron-left" aria-hidden="true"></span></a>';
                                    break;
                            }
                            break;
                    }
                    container.append(sShowHideButton);
                }


                // setTimeout(function () {
                //     switch (settings.position) {
                //         case 'right':
                //             container.animate({
                //                 right: 0
                //             });
                //             break;
                //         default:
                //             container.animate({
                //                 left: 0
                //             });
                //             break;
                //     }
                // }, 200);

                $('body').on('click', '.toggle-bar', function (e) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    $(e.currentTarget).toggleClass('close');
                    switch (settings.icons) {
                        case 'fontawesome4':
                            container.find('.cb-anchor').toggleClass('cb-hidden');
                            break;
                        case 'semanticui':
                            container.find('.cb-anchor').toggleClass('cb-hidden');
                            break;
                        default:
                            container.find('.cb-anchor').toggleClass('cb-hidden');
                            break;
                    }
                });
            }
        };

        function addButtons(container, buttons, group, icons) {
            var i, space = '';
            for (i in buttons) {
                if (Array.isArray(buttons[i])) {
                    addButtons(container, buttons[i], group, icons);
                    group = true;
                } else {
                    if (group == true) {
                        space = ' separated';
                        group = false;
                    } else {
                        space = '';
                    }
                    var bs = buttons[i];

                    if (bs.enabled) {
                        var sIcon;
                        switch (icons) {
                            case 'fontawesome4':
                                sIcon = '<span class="' + bs.icon + '" aria-hidden="true"></span>';
                                break;
                            case 'semanticui':
                                sIcon = '<i class="' + bs.icon + '"></i>';
                                break;
                            default:
                                sIcon = '<span class="' + bs.icon + '" aria-hidden="true"></span>';
                                break;
                        }
                        var button = '<a href="' + bs.link +
                            '" class="cb-link cb-anchor' + space + '" ' +
                            (bs.color ? 'style="background-color: ' + bs.color + '"' : '') +
                            (bs.title ? 'title="' + bs.title + '"' : '') +
                            (bs.extras ? bs.extras : '') +
                            ' target="_blank">' + sIcon + '</a>';
                        container.append(button);
                    }
                }
            }
        }

        $(function () {
            var el = $(".contact-bar.slide-on-scroll");
            el.attr('data-top', el.css('top'));

            $(window).scroll(function () {
                clearTimeout($.data(this, "scrollCheck"));
                $.data(this, "scrollCheck", setTimeout(function () {
                    var nTop = $(window).scrollTop() + parseInt(el.attr('data-top'));
                    el.animate({
                        top: nTop
                    }, 500);
                }, 250));
            });
        });

        $.socialfloating({
            position: 'right',
            buttons: [
                [
                    { icon: 'fas fa-arrow-up', enabled: true, link: '#top', color: '#DC5D10' },
                    { icon: 'fab fa-x-twitter', enabled: true, link: 'https://bit.ly/46wkGFS', color: '#000000' },
                    { icon: 'fab fa-youtube', enabled: true, link: 'https://www.youtube.com/@RoyalSlotGamingOfficial', color: '#FF0000' },
                    { icon: 'fab fa-facebook-f', enabled: true, link: 'https://bit.ly/RoyalSlotGaming', color: '#3b5998' },
                    { icon: 'fab fa-instagram', enabled: true, link: 'https://bit.ly/3GfTtvw', color: '#b648b8' },
                ],
            ]
        });
        
    
        $('#socialfloating a[href="#top"]').click(function () {
            var linkTarget = $(this.hash);
            console.log(linkTarget);
            $('html,body').stop(true, false).animate({
                scrollTop: linkTarget.offset().top - 0
            }, 1000);
            return false;

        });

    }(jQuery));


})

//news, contact 左邊固定效果
global.fixedLeft = (target) => {
    $(window).bind('load scroll resize', function () {
        var checkHeight = $(target).height() + $('header').height() - $(window).height()
        setTimeout(() => {}, 0)
        if (checkHeight <= $(window).scrollTop()) {
            //$('.fixed_left').addClass('p-absolute')
            //console.log($(window).scrollTop())
            $('.fixed_left').css("transform", "translate3d(0px," + parseInt(checkHeight - $(window).scrollTop()) + "px,0px)");

        } else {
            //$('.fixed_left').removeClass('p-absolute')
            $('.fixed_left').css("transform", "translate3d(0px,0px,0px)");
        }
        if (checkHeight < 0) {
            $('.fixed_left').addClass('h100p')
        } else {
            $('.fixed_left').removeClass('h100p')
        }
    });
};

//是否為觸控裝置
global.isMobile = () => {
    try {
        document.createEvent("TouchEvent");
        return true;
    } catch (e) {
        return false;
    }
};


// import '../sass/main.sass'; // 使用 ESM 方式引入