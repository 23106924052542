export function pathToWebp(attr){

    document.querySelectorAll(`[${attr}]`).forEach(e=>{

        let bgPath = e.getAttribute(attr)
        let fileExt = bgPath.slice(bgPath.lastIndexOf(".")).split(".")[1]
        let filePath = bgPath.slice(0,bgPath.lastIndexOf("."))

        if( fileExt =="jpg" || fileExt == "png"){
            e.setAttribute(attr,filePath+".webp")
        }
        
    })

}

export function dataBackgound(){

    if(broswer.broswer!="IE") {
        pathToWebp("data-background")
    }

}
